import { Injectable } from "@angular/core";

import { FetchingService } from "../../services/fetching/fetching.service";
import { HttpCacheService } from "../../services/http-cache/http-cache.service";

import { environment } from "../../../environments/environment";
import APICategory from "src/app/Models/Category.model";

@Injectable({
  providedIn: "root",
})
export class CategoriesService {
  BASE_URL = environment.baseUrl;
  PRODUCTS_ARTICLES = environment.productsArticles;

  constructor(
    private httpCache: HttpCacheService,
    private fetching: FetchingService
  ) {}

  async fetchCategories(
    cat,
    equip,
    prod,
    readCache = false,
    writeCache = true,
    setLangAndRegion = true
  ): Promise<APICategory[]> {
    if (equip === null && prod === null) {
      const url = `${this.BASE_URL}/categories?set_slug=${cat}`;
      return this.httpCache
        .get(url, readCache, writeCache, setLangAndRegion)
        .then((data) => {
          const sets = data["sets"] || {};
          const categories = sets[cat] || {};
          return Object.keys(categories).map((data) => {
            categories[data]["type"] = "category";
            return categories[data];
          });
        });
    } else {
      const url = `${this.BASE_URL}/categories?set_slug=${cat}&equipment=${equip}&product=${prod}`;
      return this.httpCache
        .get(url, readCache, writeCache, setLangAndRegion)
        .then((data) => {
          const sets = data["sets"] || {};
          const categories = sets[cat] || {};
          return Object.keys(categories).map((data) => {
            const categoryData = categories[data];
            const oldLists = categoryData["lists"][0];
            const productsArticles = environment.basicsProductsCollection;
            let newLists = {
              "Support Articles": oldLists["support-articles"],
              "The Basics Articles - Equipment": oldLists["equipment-articles"],
              productsArticles: oldLists[this.PRODUCTS_ARTICLES],
            };

            for (let key in newLists) {
              if (newLists[key] === undefined) {
                delete newLists[key];
              }
            }
            categoryData["type"] = "category";
            categoryData["articles"] = newLists;
            return categoryData;
          });
        });
    }
  }

  fetchCategoryById(
    cat,
    equip,
    prod,
    ID,
    readCache = false,
    writeCache = true
  ) {
    const url = `${this.BASE_URL}/categories?set_slug=${cat}&equipment=${equip}&product=${prod}?catID=${ID}`;
    return this.httpCache.get(url, readCache, writeCache).then((data) => {
      const sets = data["sets"] || {};
      const categories = sets[cat] || {};

      return Object.keys(categories).map((data) => {
        categories[data]["type"] = "category";
        return categories[data];
      });
    });
  }
}
